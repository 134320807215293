/*!
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

.datepicker--cell.occupied {
  background-image: linear-gradient(
                  45deg,
                  $white 41.67%,
                  rgba($danger, 0.2) 41.67%,
                  rgba($danger, 0.2) 50%,
                  $white 50%,
                  $white 91.67%,
                  rgba($danger, 0.2) 91.67%,
                  rgba($danger, 0.2) 100%
  );
  background-size: 8.49px 8.49px;
  border: 1px solid $danger;
  color: $danger;
}

.datepicker--cell.occupied.-focus- {
  color: $danger;
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current-,
.datepicker--cell.-in-range-,
.-in-range-.datepicker--cell-day.-other-month-,
.-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba($primary, 0.1);
}

.datepicker--cell.-in-range-.-focus-,
.-in-range-.-focus-.datepicker--cell-day.-other-month-,
.-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba($primary, 0.2);
  color: $white;
}

.datepicker--cell.-range-from-,
.datepicker--cell.-range-to-,
.datepicker--cell.-selected-.-focus-,
.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade-,
.-selected-.-focus-.datepicker--cell-day.-other-month-,
.-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba($primary, 1);
  color: $white;
}

.datepicker--button {
  color: $primary;
}