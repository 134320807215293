/*!
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

h1 {
  margin-bottom: $grid-gutter-width;
}

nav,
ul.nav {

  &,
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    display: block;
  }
}

a.btn,
button.btn {
  box-shadow: 0 1px 1px 0 rgba(60,75,100,.14), 0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2);
}

table {
  @include border-radius($border-radius);

  &.list {
    tbody tr:hover td {
      background: $table-hover-bg;
      color: $table-hover-color;
    }
  }

  thead {
    th {
      a {
        span {
          position: relative;

          &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
          }
        }
      }

      a.asc span:before {
        content: '\f0d8';
      }

      a.desc span:before {
        content: '\f0d7';
      }
    }
  }
}