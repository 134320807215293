/*!
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

label[for] {
  cursor: pointer;
}

label.required:after {
  content: '*';
}