/*!
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

// Bootstrap settings
//$primary: #2d446d;
$primary: #321fdb;
$secondary: #ced2d8;
$success: #2eb85c;
$danger: #e55353;
$warning: #f9b115;
$info: #3399ff;
$light: #ebedef;
$dark: #636f83;

$gray-100: #ebedef;
$gray-200: #d8dbe0;
$gray-300: #c4c9d0;
$gray-400: #b1b7c1;
$gray-500: #9da5b1;
$gray-600: #8a93a2;
$gray-700: #768192;
$gray-800: #636f83;
$gray-900: #4f5d73;

$table-border-color: $gray-200;

//$grid-gutter-width: 2rem;
//
//$grid-breakpoints: (
//  xl: 1900px
//);
//
//$container-max-widths: (
//  xl: 1860px
//);

// Project colors