/*!
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

//@import "~bootstrap/scss/bootstrap";
@import "_variables";
@import "~@coreui/coreui/scss/coreui";
@import "globals";
@import "forms";
@import "datepicker";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #EBEDF3;
  font-size: .875rem;
  color: #3c4b64;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.sidebar-left {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex: 0 0 256px;
  flex-direction: column;
  width: 256px;
  margin-left: -256px;
  background: #3c4b64;
  @include transition(margin-left .25s ease);

  &.sidebar-show {
    margin-left: 0;
  }

  .logo {
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    color: $white;
    font-size: 1rem;

    &,
    &:hover {
      text-decoration: none;
    }
  }

  nav {
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      display: flex;
      flex: 1;
      flex-direction: column;

      li {

        a {
          i, svg {
            display: inline-block;
            margin-right: 0.5rem;
          }
        }

        a,
        &.active a {
          display: block;
          padding: 0.8rem 1rem;
          color: rgba($white, 0.8);
          text-overflow: ellipsis;
          white-space: nowrap;
          @include transition(background .25s, color .25s);
        }

        & a,
        & a:hover,
        &.active a {
          text-decoration: none;
        }

        & a:hover,
        &.active a {
          color: $white;
        }

        a:hover {
          background: $primary;
        }

        &.active a {
          background: rgba($white, 0.05);
        }
      }
    }
  }
}

.sidebar-left:not(.sidebar-show) + .wrapper {
  margin-left: 0;
}

.wrapper {
  //display: block;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 256px;
  @include transition(margin-left .25s ease);
  overflow-y: scroll;

  header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    height: 3rem;
    padding: 0 1rem;
    background: $white;
    border: 0;
    box-shadow: 0 2px 2px 0 rgba(60, 75, 100, .14), 0 3px 1px -2px rgba(60, 75, 100, .12), 0 1px 5px 0 rgba(60, 75, 100, .2);

    button.menu-toggler {
      background: none;
      border: 0;
    }

    a {
      color: rgba(0, 0, 21, .5);

      &:hover {
        color: rgba(0, 0, 21, .7);
        text-decoration: none;
      }
    }

    ul.nav {
      li {
        position: relative;
        padding: 0 0.8rem;

        &.last {
          padding-right: 0;
        }

        a {
          position: relative;

          .badge {
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            @include border-radius($border-radius);
          }
        }
      }

      &.nav-right {
        margin-left: auto;
      }

      .dropdown-menu {
        width: auto;
        border: 0;
        box-shadow: 0 4px 5px 0 rgba(60, 75, 100, .14), 0 1px 10px 0 rgba(60, 75, 100, .12), 0 2px 4px -1px rgba(60, 75, 100, .2);
      }
    }


    a.user {
      padding: 0.5rem;
      margin-left: 0.5rem;
      background: $gray-100;
      @include border-radius(50%);
      text-align: center;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  main {
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 1;
    //min-width: 0;
    padding-top: 2rem;

    //padding-top: 5rem;
    //padding-left: $grid-gutter-width;
    //padding-right: $grid-gutter-width;
    //margin-left: auto;
  }

  footer {
    display: flex;
    flex: 0 0 50px;
    flex-wrap: wrap;
    align-items: center;
    height: 50px;
    padding: 0 1rem;
    color: #4f5d73;
    background: #ebedef;
    border-top: 1px solid #d8dbe0;
  }
}

.container {
  position: relative;
  padding: $grid-gutter-width 0;
}

.tab-content {
  padding: $grid-gutter-width / 2;
  margin-top: -1px;
  border: 1px solid $gray-300;
}

.toast {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  border: 0;
  border-radius: .25rem;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, .14), 0 2px 1px -1px rgba(60, 75, 100, .12), 0 1px 3px 0 rgba(60, 75, 100, .2);

  .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid;
    background: $white;
    border-color: #d8dbe0;
  }
}

.buttons {
  display: flex;
  margin-bottom: 1.5rem;

  button,
  a {
    margin: 0 1px;
  }

  .buttons-right {
    margin-left: auto;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    flex-wrap: wrap;

    .buttons-right {
      margin-left: 0;
    }
  }
}

.search_list.guests {

  .id,
  .options {
    width: 50px;
  }

  .options {
    text-align: right;
  }
}

.bg-gradient-info {
  background: #2982cc!important;
  background: linear-gradient(45deg,#39f 0%,#2982cc 100%)!important;
  border-color: #2982cc!important
}

.bg-gradient-danger {
  background: #d93737!important;
  background: linear-gradient(45deg,#e55353 0%,#d93737 100%)!important;
  border-color: #d93737!important;
}

.bg-gradient-warning {
  background: #f6960b!important;
  background: linear-gradient(45deg,#f9b115 0%,#f6960b 100%)!important;
  border-color: #f6960b!important;
}

.bg-gradient-success {
  background: #1b9e3e!important;
  background: linear-gradient(45deg,#2eb85c 0%,#1b9e3e 100%)!important;
  border-color: #1b9e3e!important
}